<template>
  <div id="page">

  <PageHeader :pageTitle="pageTitle" :pageDescription="pageDescription"></PageHeader>


    <v-row dense>

        <v-col class="col-6">
          Card
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="col-6">
          Card
        </v-col>

      </v-row>
  </div>
</template>


<script>

import apiRequest from "@/mixins/apiRequest"
import PageHeader from '@/components/ui/PageHeader.vue'

export default {

  name: 'Settings',

  components: {
    PageHeader,
  },

  data () {
    return {
      pageTitle: 'Persönliche Einstellungen:',
      pageDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non officiis accusantium omnis ad? Porro ea perferendis veritatis repellat sit et nulla quae ab omnis, eos beatae facilis obcaecati aliquam harum!',
      title: '',
      preloader: false,
      data: []
    }
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {

    async getData() {

      this.preloader= true;

      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest('companies/'+this.$route.params.id); // await data from mixin
      this.preloader= false;
      
      this.data = response.data.data;
      this.title = response.data.data.name;

    }

  },

  mixins: [apiRequest],

}
</script>