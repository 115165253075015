<template>
  <div class="login">
    <!-- <div>
      <form @submit.prevent="submit">
        <div>
          <label for="username">Username:</label>
          <input type="text" name="username" v-model="form.username" />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" name="password" v-model="form.password" />
        </div>
        <button type="submit">Submit</button>
      </form>
      <p v-if="showError" id="error">Username or Password is incorrect</p>
    </div> -->

    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4 elevation-6>
        <v-toolbar class="primary">
          <v-toolbar-title class="white--text">Willkommen</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-card-text class="pt-4">
            <v-form ref="form">
              <v-text-field label="Benutzername" v-model="form.username" required></v-text-field>
              <v-text-field label="Passwort" v-model="form.password" :type="show1 ? 'text' : 'password'" min="8" counter required></v-text-field>
              <v-layout justify-space-between>
                  <v-btn @click="submit" class="primary white--text">Login</v-btn>
                  <a href="">Forgot Password</a>
              </v-layout>
            </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>

  </div>
</template>





<script>
import { mapActions } from "vuex"; // import actions from vuex (eg. store/modules/auth.js)

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      show1: false,
        show2: true,
        show3: false,
        show4: false,
        password: 'Password',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      showError: false
    };
  },
  methods: {

    ...mapActions({ // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogIn : 'auth/LogIn'
    }),

    submit() {

      const User = new FormData();
      User.append("loginname", this.form.username);
      User.append("password", this.form.password);

      this.LogIn(User).then(() => {
        
        this.$router.push('/dashboard')
        this.showError = false

      }).catch(() => {

        alert("Loginversuch gescheitert");
        
      })

    }


  }

};
</script>
